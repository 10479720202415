import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { ImageType, SocialType, BlogFormatType } from "@lendex/helper/types";
import { Heading, BlogWidget } from "gatsby-theme-ui";
import {
    CategoryItem,
    ResumeItem,
    TagItem,
    BlogComment,
} from "gatsby-theme-component";

const BlogDetails = ({ data }) => {
    const pageLink =
        typeof window !== "undefined" ? window.location.href : null;
    const hashtags = data?.tags?.map((tag) => tag.title.replace(/ /g, "_"));
    return (
        <div className="Blog-details-section section-space-ptb">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                    <div className="lg:col-span-4 order-last lg:order-first mt-10 md:mt-0">
                        <BlogWidget>
                            <h3 className="mb-9 md:text-[48px]">Category</h3>
                            <CategoryItem />
                        </BlogWidget>
                        <BlogWidget>
                            <h3 className="mb-9  md:text-[48px]">Resume</h3>
                            <ResumeItem />
                        </BlogWidget>
                        <BlogWidget>
                            <h3 className="mb-9  md:text-[48px]">Tags</h3>
                            <TagItem />
                        </BlogWidget>
                    </div>
                    <div className="lg:col-span-8">
                        <div className="content-meta relative flex flex-col md:flex-row justify-between mb-8">
                            <span className="section-cetagory ml-6 capitalize text-white before:content-['||'] before:absolute before:w-full before:h-full before:left-0">
                                {data?.category?.title}
                            </span>
                            <div className="post-meta-2 mt-4 md:mt-0">
                                <span className="icon-space-right capitalize mr-5 lg:mr-14 text-white">
                                    <i className="icofont-ui-user mr-3" />
                                    by {data?.author?.username}
                                </span>
                                <span className="icon-space-right text-white">
                                    <i className="icofont-calendar mr-3" />
                                    {data?.date}
                                </span>
                            </div>
                        </div>
                        {data?.title && (
                            <Heading className="mb-8">{data.title}</Heading>
                        )}
                        <div
                            className="blog-content"
                            dangerouslySetInnerHTML={{
                                __html: data?.content || "Blog Content",
                            }}
                        />
                        <div className="tag bg-secondary-80 relative px-8 py-8 flex flex-col md:flex-row items-center justify-between">
                            <div className="tag-wrap">
                                <p className=" font-semibold text-white mb-4">
                                    Tags:
                                </p>
                                {data?.tags.map((item) => (
                                    <Link
                                        to={item.slug}
                                        key={item}
                                        className=" pr-2 hover:text-white after:content-[','] after:right-0 last:after:sr-only"
                                    >
                                        {item?.title}
                                    </Link>
                                ))}
                            </div>
                            <div className="share-post mt-8 md:mt-0">
                                <a
                                    className="h-16 w-16 inline-block text-white mr-5 last:mr-0 leading-[64px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full"
                                    href={`http://www.facebook.com/sharer.php?u=${pageLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to facebook"
                                >
                                    <i className="icofont-facebook" />
                                </a>
                                <a
                                    className="h-16 w-16 inline-block text-white mr-5 last:mr-0 leading-[64px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full"
                                    href={`http://twitter.com/share?url=${pageLink}&text=${data.title}&hashtags=${hashtags}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to twitter"
                                    sx={{ color: "twitter" }}
                                >
                                    <i className="icofont-twitter" />
                                </a>
                                <a
                                    className="h-16 w-16 inline-block text-white mr-5 last:mr-0 leading-[64px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full"
                                    href={`http://www.linkedin.com/shareArticle?mini=true&url=${pageLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to linkedin"
                                    sx={{ color: "linkedin" }}
                                >
                                    <i className="icofont-linkedin" />
                                </a>
                            </div>
                        </div>

                        <BlogComment
                            slug={data.slug}
                            title={data.title}
                            siteUrl={data.siteUrl}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

BlogDetails.propTypes = {
    data: PropTypes.shape({
        siteUrl: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
        format: BlogFormatType,
        video_link: PropTypes.string,
        gallery_images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        image: PropTypes.shape(ImageType),
        author: PropTypes.shape({
            username: PropTypes.string,
            slug: PropTypes.string,
            designation: PropTypes.string,
            avatar: PropTypes.shape(ImageType),
            bio: PropTypes.string,
            socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
        }),
        date: PropTypes.string,
        category: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
        content: PropTypes.string,
    }),
};
export default BlogDetails;
