import PropTypes from "prop-types";
import "@lendex/shared/fonts/icofont.min.css";
// import { baseTheme } from "@lendex/shared/themes";

const Layout = ({ children }) => <div className="wrappper">{children}</div>;
Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
