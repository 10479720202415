import PropTypes from "prop-types";
import cn from "classnames";
import { DiscussionEmbed } from "disqus-react";
import { disqusShortname } from "@lendex/shared/data/api-keys";

const BlogComment = ({ slug, title, siteUrl, className, sx }) => {
    const disqusConfig = {
        url: siteUrl + slug,
        identifier: slug,
        title,
    };
    return (
        <div className={cn("blog-comment mt-14", className)} sx={sx}>
            <div className="mb-10">
                <h3 className="mb-4">Leave your thought here</h3>
            </div>
            <DiscussionEmbed
                shortname={disqusShortname}
                config={disqusConfig}
            />
        </div>
    );
};

BlogComment.propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    siteUrl: PropTypes.string,
    className: PropTypes.string,
    sx: PropTypes.shape({}),
};

export default BlogComment;
