import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const BurgerButton = ({ onClick, className, label }) => (
    <div
        className={cn(
            className,
            "burger-btn ml-6 w-6 h-4 border-0 relative bg-transparent"
        )}
        type="button"
        onClick={onClick}
        onKeyUp={onClick.handleKeyUp}
        label={label}
        role="button"
        tabIndex="0"
    >
        <span className=" bg-white rounded-sm absolute h-0.5 w-full top-0 left-0 block" />
        <span className="bg-white rounded-sm absolute h-0.5 w-full top-0 left-0 block" />
        <span className="bg-white rounded-sm absolute h-0.5 w-full bottom-0 left-0 block" />
    </div>
);
BurgerButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    sx: PropTypes.shape({}),
    label: PropTypes.string,
};

BurgerButton.defaultProps = {
    label: "Click to open menu",
};

export default BurgerButton;
