import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { ImageType } from "@lendex/helper/types";
import { Image } from "gatsby-theme-ui";

const FunFactArea = ({ title, number, images }) => {
    const [focus, setFocus] = useState(false);
    const visibleChangeHandler = (isVisible) => {
        if (isVisible) {
            if (!focus) {
                setFocus(true);
            }
        }
    };
    return (
        <div className="counterup-single-item flex flex-col lg:flex-row text-center lg:text-left justify-items-center mb-10 sm:mb-0">
            <div className="icon mb-9 mx-auto lg:mb-0 lg:mr-12 border border-solid border-[#40424d] rounded-lg items-center text-center h-[70px] w-[70px] leading-[70px]">
                <Image
                    className="align-middle"
                    src={images?.src}
                    alt={images?.alt || "Counter Icon"}
                />
            </div>

            <div className="content">
                <CountUp start={focus ? 0 : null} end={number} duration={5}>
                    {({ countUpRef }) => (
                        <h3 className="counterup font-poppins text-white font-light text-[52px] mb-6">
                            <span ref={countUpRef} />
                            <VisibilitySensor
                                onChange={(isVisible) =>
                                    visibleChangeHandler(isVisible)
                                }
                            >
                                <span className="plus">+</span>
                            </VisibilitySensor>
                        </h3>
                    )}
                </CountUp>
                <span>{title}</span>
            </div>
        </div>
    );
};
FunFactArea.propTypes = {
    title: PropTypes.string,
    number: PropTypes.number.isRequired,
    images: PropTypes.shape(ImageType),
};

export default FunFactArea;
