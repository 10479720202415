import PropTypes from "prop-types";
import { ContactForm, SectionTitle } from "gatsby-theme-component";
import { ImageType } from "@lendex/helper/types";
import { SectionTitleType } from "@lendex/shared";

const ContactUsArea = ({ data }) => (
    <section className="contact-us-section section-space-pb">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-7">
                <div className="md:col-span-5 lg:col-span-4 z-10 md:-mt-96">
                    <div className="contact-sidebar bg-secondary-80 px-7 py-12 lg:px-[60px] lg:py-20">
                        <ul className="contact-sidebar-list-item">
                            <li className="single-box mb-5 px-[35px] py-[30px] hover:bg-transparent bg-secondary-90 border border-solid border-secondary-90 hover:border-white/10">
                                <h6 className="title-text font-poppins mb-3">
                                    Phone:
                                </h6>
                                {data?.contact_info.phone && (
                                    <a
                                        href={`tel:${data?.contact_info.phone}`}
                                        className="link"
                                    >
                                        {data?.contact_info.phone}
                                    </a>
                                )}
                            </li>
                            <li className="single-box mb-5 px-[35px] py-[30px] hover:bg-transparent bg-secondary-90 border border-solid border-secondary-90 hover:border-white/10">
                                <h6 className="title-text font-poppins mb-3">
                                    Email:
                                </h6>
                                {data?.contact_info.email && (
                                    <a
                                        href={`mailto:${data?.contact_info.email}`}
                                        className="link"
                                    >
                                        {data?.contact_info.email}
                                    </a>
                                )}
                            </li>
                            <li className="single-box mb-5 px-[35px] py-[30px] hover:bg-transparent bg-secondary-90 border border-solid border-secondary-90 hover:border-white/10">
                                <h6 className="title-text font-poppins mb-3">
                                    Website:
                                </h6>
                                {data?.contact_info.website && (
                                    <a
                                        href={data?.contact_info.website}
                                        className="link"
                                    >
                                        {data?.contact_info.website}
                                    </a>
                                )}
                            </li>
                            <li className="single-box mb-5 px-[35px] py-[30px] hover:bg-transparent bg-secondary-90 border border-solid border-secondary-90 hover:border-white/10">
                                <h6 className="title-text font-poppins mb-3">
                                    Address:
                                </h6>
                                {data?.contact_info.address && (
                                    <span className="text">
                                        {" "}
                                        {data?.contact_info.address}
                                    </span>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="socials-share mt-20 bg-secondary-80 px-7 py-12 md:px-[60px] md:py-20">
                        <h3 className="title mb-10">Follow Me</h3>
                        {data?.socials &&
                            data?.socials.map((item) => (
                                <a
                                    key={item.id}
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="h-16 w-16 inline-block text-white mr-5 last:mr-0 leading-[64px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full"
                                >
                                    <i className={item.icon} />
                                </a>
                            ))}
                    </div>
                </div>
                <div className="md:col-span-7 lg:col-span-8">
                    <div className="xl:ml-16">
                        <SectionTitle {...data.section_title} />
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    </section>
);
ContactUsArea.propTypes = {
    data: PropTypes.shape({
        copyright_text: PropTypes.string,
        contact_info: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string,
            website: PropTypes.string,
            address: PropTypes.string,
        }),
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        section_title: PropTypes.shape(SectionTitleType),
    }),
};
export default ContactUsArea;
