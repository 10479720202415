import React from "react";
import PropTypes from "prop-types";
import { ItemType } from "@lendex/shared";
import {
    BlogCard02,
    CategoryItem,
    Pagination,
    ResumeItem,
    TagItem,
} from "gatsby-theme-component";
import { BlogWidget } from "gatsby-theme-ui";

const Article = ({ data }) => (
    <section className="blog-section section-space-ptb 1769">
        <div className="container">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                <div className="lg:col-span-8">
                    {data?.items?.map((post) => (
                        <BlogCard02
                            key={post?.id}
                            title={post?.title}
                            author={post?.author}
                            date={post?.date}
                            slug={post?.slug}
                            image={post?.image}
                            format={post?.format}
                            video_link={post?.video_link}
                            category={post?.category}
                            gallery_images={post?.gallery_images}
                        />
                    ))}
                    {data?.pagination && data.pagination?.numberOfPages > 1 && (
                        <div>
                            <Pagination {...data.pagination} />
                        </div>
                    )}
                </div>
                <div className="lg:col-span-4 ">
                    <BlogWidget>
                        <h3 className="mb-9 md:text-[48px]">Category</h3>
                        <CategoryItem />
                    </BlogWidget>
                    <BlogWidget>
                        <h3 className="mb-9  md:text-[48px]">Resume</h3>
                        <ResumeItem />
                    </BlogWidget>
                    <BlogWidget>
                        <h3 className="mb-9  md:text-[48px]">Tags</h3>
                        <TagItem />
                    </BlogWidget>
                </div>
            </div>
        </div>
    </section>
);
Article.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        pagination: PropTypes.shape({
            rootPage: PropTypes.string,
            numberOfPages: PropTypes.number,
            currentPage: PropTypes.number,
        }),
    }),
};
export default Article;
