import React from "react";
import PropTypes from "prop-types";
import { ItemType } from "@lendex/shared";
import { BlogCard02, Pagination } from "gatsby-theme-component";

const Article = ({ data }) => (
    <section className="blog-section section-space-ptb 1769">
        <div className="container">
            {data?.items?.map((post) => (
                <BlogCard02
                    key={post?.id}
                    title={post?.title}
                    author={post?.author}
                    date={post?.date}
                    slug={post?.slug}
                    image={post?.image}
                    format={post?.format}
                    video_link={post?.video_link}
                    category={post?.category}
                    gallery_images={post?.gallery_images}
                />
            ))}
            {data?.pagination && data.pagination?.numberOfPages > 1 && (
                <div>
                    <Pagination {...data.pagination} />
                </div>
            )}
        </div>
    </section>
);
Article.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        pagination: PropTypes.shape({
            rootPage: PropTypes.string,
            numberOfPages: PropTypes.number,
            currentPage: PropTypes.number,
        }),
    }),
};
export default Article;
