import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ButtonType, ItemType } from "@lendex/shared";
import { ServiceCard } from "gatsby-theme-component";

const ServicesArea = ({ data }) => (
    <section className="service-section section-space-ptb">
        <div className="container">
            <div className="grid grid-cols-3 gap-8">
                {data?.items?.map((item) => (
                    <ServiceCard
                        key={item.id}
                        title={item?.title}
                        features={item?.features}
                        images={item?.images?.[0]}
                    />
                ))}
            </div>
        </div>
    </section>
);
ServicesArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default ServicesArea;
