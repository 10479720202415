/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { BreadCrumb01 } from "gatsby-theme-component";

const PageHeader = ({ data }) => (
    <div className="page-title-area pt-44 pb-24 lg:pt-[275px] lg:pb-[180px] bg-secondary-80 relative overflow-hidden">
        <div
            className="shape-top-left invisible md:visible absolute top-[-425px] left-[-595px] w-[800px] h-[800px] rounded-full border border-[rgba(255,255,255,.05)]
        after:absolute after:w-[1065px] after:h-[1065px] after:rounded-full after:border after:border-[rgba(255,255,255,.05)] after:top-[-140px] after:right-[-140px]
        before:absolute before:w-[935px] before:h-[935px] before:rounded-full before:border before:border-[rgba(255,255,255,.05)] before:top-[-70px] before:right-[-70px]"
        />
        <div
            className="shape-bottom-right invisible md:visible absolute bottom-[-425px] right-[-595px] w-[800px] h-[800px] rounded-full border border-[rgba(255,255,255,.05)]
         after:absolute after:w-[1063px] after:h-[1063px] after:rounded-full after:border after:border-[rgba(255,255,255,.05)] after:top-[-140px] after:right-[-140px]
         before:absolute before:w-[935px] before:h-[935px] before:rounded-full before:border before:border-[rgba(255,255,255,.05)] before:top-[-70px] before:right-[-70px]"
        />
        <div className="container">
            {data?.pageName && (
                <BreadCrumb01 pageName={data.pageName} showTitle />
            )}
        </div>
    </div>
);
PageHeader.propTypes = {
    data: PropTypes.shape({
        pageName: PropTypes.string,
    }),
};

export default PageHeader;
