import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, TextType } from "@lendex/helper/types";

const ProjectContent = ({ data }) => (
    <div className="mt-8">
        {data?.texts?.map((text) => (
            <p key={text.content} className="mb-4">
                {text.content}
            </p>
        ))}
    </div>
);

ProjectContent.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        section_title: PropTypes.shape(SectionTitleType),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default ProjectContent;
