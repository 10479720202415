import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ItemType } from "@lendex/shared";
import { SectionTitle, SkillItem } from "gatsby-theme-component";

const SkillArea = ({ data }) => (
    <section className="skill-section section-space-ptb bg-secondary-80">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="col-span-1 mb-10">
                    {data?.section_title && (
                        <SectionTitle {...data.section_title} />
                    )}

                    <div className="skill-action">
                        <a
                            href="/"
                            className="btn hover:bg-secondary px-5 md:px-5 lg:px-10 h-16 leading-[65px] text-white inline-block md:mt-0 border border-solid border-secondary rounded-full"
                        >
                            Get Resume
                            <i className="ml-4 icofont-download" />
                        </a>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="skill-wrap lg:ml-16">
                        {data?.items?.map((item) => (
                            <SkillItem
                                key={item.id}
                                title={item?.title}
                                completed={item?.number}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
);
SkillArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default SkillArea;
