import PropTypes from "prop-types";

const CloseButton = ({ onClick, className, label }) => (
    <button
        onClick={onClick}
        className={`${className} text-2xl`}
        aria-label={label}
        type="button"
    >
        <i className="icofont-close text-black" />
    </button>
);
CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
};
export default CloseButton;
