import React from "react";
import PropTypes from "prop-types";

const TestimonialCard = ({
    title,
    description,
    designation,
    rating,
    company,
}) => {
    const arr1 = [];
    const arr2 = [];
    for (let i = 0; i < rating; i++) {
        arr1.push(<i key={i} className="icofont-star mr-1" />);
    }
    if (rating < 5) {
        for (let j = 1; j <= 5 - rating; j++) {
            arr2.push(<i key={j} className="icofont-star mr-1" />);
        }
    }

    return (
        <div className="testimonial-slider-single-item pt-[165px]  pb-[70px] px-7 lg:px-[55px] bg-secondary-70 overflow-hidden relative">
            <div
                className="inner-shape absolute top-[-230px] right-[-230px] w-[345px] h-[345px] rounded-full border border-[rgba(255,255,255,.05)]
         after:absolute after:w-[460px] after:h-[460px] after:rounded-full after:border after:border-[rgba(255,255,255,.05)] after:top-[-40px] after:right-[-40px]
         before:absolute before:w-[400px] before:h-[400px] before:rounded-full before:border before:border-[rgba(255,255,255,.05)] before:top-[-20px] before:right-[-20px]"
            />
            <div className="content">
                <span className="icon absolute text-[185px] left-[55px] top-[60px] leading-none font-garamond">
                    “
                </span>
                <p className="text text-[25px] lg:text-[35px] text-white font-semibold leading-snug font-garamond">
                    {description}
                </p>
                <div className="info mt-6 flex items-center justify-between">
                    <div className="author">
                        <h3 className="name mb-3 font-poppins text-[22px] md:text-[28px]">
                            {title}
                        </h3>
                        <span className="designation mt-4">
                            {designation}, {company}
                        </span>
                    </div>
                    <ul className="review flex">
                        <li className="text-[#ffa200]">{arr1.slice(0, 5)}</li>
                        <li>{arr2}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
TestimonialCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    designation: PropTypes.string,
    company: PropTypes.string,
    rating: PropTypes.number,
};
export default TestimonialCard;
