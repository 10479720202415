import { useState } from "react";
import PropTypes from "prop-types";
import { HeadingType, TextType, VideoButtonType } from "@lendex/helper/types";
import { Heading, ModalVideo } from "gatsby-theme-ui";

const AboutUsArea = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClickHandler = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <section className="about-us-section section-space-ptb">
            <div className="container">
                <div className="grid grid-cols-2 gap-20 items-center">
                    <div className="bg-secondary-80 h-[463.5px] items-center flex flex-col justify-center px-8 py-8 text-center">
                        <div className="video-link flex items-center justify-center mb-20">
                            <button
                                className="wave-btn relative h-24 w-24 leading-[96px] z-10 bg-secondary-90 text-center rounded-full"
                                onClick={onClickHandler}
                                type="button"
                            >
                                <span className="animate-ping inline-flex h-full w-full rounded-full -z-10 bg-[#494c5f] opacity-75" />
                                <div className="ripple z-10 absolute top-0 left-1/2">
                                    <i className="icofont-ui-play" />
                                </div>
                            </button>
                            <ModalVideo
                                isOpen={isOpen}
                                channel={data?.video_button?.video_platform}
                                videoId={data?.video_button?.video_id}
                                onClose={onClickHandler}
                            />
                        </div>
                        <a
                            href="/"
                            className="btn px-5 md:px-5 lg:px-10 h-20 leading-[80px] text-white text-[18px] inline-block md:mt-0 border border-solid border-secondary hover:bg-secondary rounded-full"
                        >
                            Get Resume
                            <i className="ml-5 icofont-double-right" />
                        </a>
                    </div>
                    <div>
                        {data?.headings?.[0] && (
                            <Heading
                                level={data.headings[0]?.level}
                                className="title-big text-white mb-7"
                            >
                                {data.headings[0].content}
                            </Heading>
                        )}
                        {data?.headings?.[1] && (
                            <Heading
                                level={data.headings[1]?.level}
                                className="title-large text-white"
                            >
                                {data.headings[1].content}
                            </Heading>
                        )}
                        {data?.texts?.[0] && (
                            <p
                                className="text-base text-white mt-7 "
                                dangerouslySetInnerHTML={{
                                    __html: data?.texts[0].content,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
AboutUsArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        video_button: PropTypes.shape(VideoButtonType),
    }),
};
export default AboutUsArea;
