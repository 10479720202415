/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { HeadingType, ItemType, TextType } from "@lendex/helper/types";

const ResumeItem = ({ data }) => (
    <div className="tab-content-box">
        <ul className="resume-list mt-20">
            {data?.items?.map((item) => (
                <li
                    key={item.id}
                    className="resume-tab-list-single-item content flex flex-col md:flex-row"
                >
                    <div className="left first:border-t fisrt:border-solid fisrt:border-white/10 border-b mr-0 sm:mr-10 md:mr-20 md:w-52 lg:w-[300px] relative md:after:content-[''] after:bg-transparent md:after:bg-white after:-right-0.5 after:-translate-x-1/2 after:h-12 after:w-[1px] after:top-1/2 shrink-0 items-center flex justify-start md:justify-end border-l border-r border-solid border-white/10 ">
                        <span className="year px-8">{item.company}</span>
                    </div>
                    <div className="right py-8">
                        <h3>{item.title}</h3>
                        <p className="my-6">{item.designation}</p>
                        <p>{item.description}</p>
                    </div>
                </li>
            ))}
        </ul>
    </div>
);
ResumeItem.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default ResumeItem;
