/* eslint-disable max-len */
import { Children } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";

import { SectionTitleType } from "@lendex/helper/types";

const ResumeTabArea = ({ children }) => {
    const childArr = Children.toArray(children);
    return (
        <section className="resume-section section-space-pb">
            <div className="container">
                <Tabs>
                    <TabList className="grid grid-cols-1 xs:grid-cols-2 gap-8 md:gap-12">
                        {childArr?.map((item) => (
                            <Tab
                                key={item.key}
                                className="px-5 text-center border border-solid border-white/10 py-5 md:py-6 text-2xl md:text-[32px] lg:text-[52px] text-white font-garamond react-tabs__tab--selected:bg-black "
                            >
                                <div className="nav-btn">
                                    {item?.props?.data?.section_title
                                        ?.title && (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.props.data
                                                    .section_title.title,
                                            }}
                                        />
                                    )}
                                </div>
                            </Tab>
                        ))}
                    </TabList>
                    <div className="tab-content">
                        {childArr?.map((item) => (
                            <TabPanel key={item.key}>{item}</TabPanel>
                        ))}
                    </div>
                </Tabs>
            </div>
        </section>
    );
};
ResumeTabArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
    }),
    children: PropTypes.node.isRequired,
};
export default ResumeTabArea;
