/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@lendex/helper/types";
import { NewsletterOne, SectionTitle } from "gatsby-theme-component";
import { SectionTitleType } from "@lendex/shared";
import { Image } from "gatsby-theme-ui";

const FooterArea = ({ data }) => (
    <footer className="footer-area section-space-pt bg-secondary-80">
        <div className="container">
            <div className="newsletter-area">
                {data?.section_title && (
                    <div className="header-title text-center">
                        <SectionTitle {...data.section_title} />
                    </div>
                )}
                <NewsletterOne />
            </div>
            <div className="footer-center-area section-space-ptb">
                <div className="contact-info-wrap flex flex-col md:flex-row items-center justify-between">
                    {data?.contact_info.phone && (
                        <a
                            href={`tel:${data?.contact_info.phone}`}
                            className="info-box my-4 flex items-center"
                        >
                            <span className="icon h-20 w-20 inline-block text-white mr-5 leading-[80px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full">
                                <i className="icofont-phone" />
                            </span>
                            <span className="text text-white text-[20px] xl:text-[32px]">
                                {data?.contact_info?.phone}
                            </span>
                        </a>
                    )}
                    {data?.contact_info.email && (
                        <a
                            href={`mailto:${data?.contact_info.email}`}
                            className="info-box my-4 flex items-center"
                        >
                            <span className="icon h-20 w-20 inline-block text-white mr-5 leading-[80px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full">
                                <i className="icofont-envelope-open" />
                            </span>
                            <span className="text text-white text-[20px] xl:text-[32px]">
                                {data?.contact_info?.email}
                            </span>
                        </a>
                    )}
                    <div className="footer-single-info my-4 flex">
                        {data?.socials &&
                            data?.socials.map((item) => (
                                <a
                                    key={item.id}
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label={item.title}
                                    className="h-16 w-16 inline-block text-white hover:text-black hover:bg-white mr-5 last:mr-0 leading-[64px] border border-solid border-[rgba(255,255,255,.1)] text-center rounded-full"
                                >
                                    <i className={item.icon} />
                                </a>
                            ))}
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom-area py-4 border-t border-solid border-[rgba(255,255,255,.1)]">
            <div className="container">
                <div className="footer-bottom-text flex justify-between items-center">
                    <div className="copy-right-area py-2">
                        <p>{data?.copyright_text}</p>
                    </div>
                    <div className="logo pb-2">
                        <Image
                            src={data?.logo[0]?.src}
                            alt={data?.logo[0]?.alt}
                        />
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
FooterArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        copyright_text: PropTypes.string,
        contact_info: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string,
        }),
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    }),
};
export default FooterArea;
