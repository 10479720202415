import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, TextType } from "@lendex/helper/types";

const ProjectSummery = ({ data }) => (
    <div className="mt-14">
        {data?.section_title && (
            <h2 className="mb-6 lg:text-[50px] font-semibold">
                {data?.section_title?.title}
            </h2>
        )}
        {data?.texts?.map((text) => (
            <p key={text.content} className="mb-4">
                {text.content}
            </p>
        ))}
    </div>
);

ProjectSummery.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default ProjectSummery;
