import PropTypes from "prop-types";
import {
    GoogleMap as GoogleMapComponent,
    useJsApiLoader,
    Marker as MarkerComponent,
} from "@react-google-maps/api";
import { MapKey } from "@lendex/shared/data/api-keys";

const GoogleMap = ({ lat, lng, zoom }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: MapKey,
    });
    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }
    return isLoaded ? (
        <div className="section-space-ptb">
            <div className="container">
                <div className="w-full h-96 md:h-[600px]">
                    <GoogleMapComponent
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        center={{ lat, lng }}
                        zoom={zoom}
                    >
                        <MarkerComponent position={{ lat, lng }} />
                    </GoogleMapComponent>
                </div>
            </div>
        </div>
    ) : (
        <div>Map is loading.</div>
    );
};

GoogleMap.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    sx: PropTypes.shape({}),
};

GoogleMap.defaultProps = {
    lat: -3.745,
    lng: -38.523,
    zoom: 12,
};

export default GoogleMap;
