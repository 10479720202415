import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@lendex/helper/types";

const ProjectHero = ({ data }) => (
    <div className="project-top-areas">
        <h2>{data.title}</h2>
    </div>
);

ProjectHero.propTypes = {
    data: PropTypes.shape({
        image: PropTypes.shape(ImageType),
        title: PropTypes.string,
    }),
};

export default ProjectHero;
