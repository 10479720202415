/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "@ramonak/react-progress-bar";

const Progress = ({ completed }) => {
    const progressItem = `progressItem bg-[#40424d] relative w-full`;
    return (
        <ProgressBar
            barContainerClassName={progressItem}
            height="5px"
            borderRadius="0px"
            completed={completed}
            ariaValuetext={completed}
        />
    );
};
Progress.propTypes = {
    completed: PropTypes.number,
};

export default Progress;
