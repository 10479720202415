/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "gatsby";

const Resume = () => (
    <div>
        <Link
            to="/"
            className="flex justify-between mb-3 last:mb-0 px-7 py-5 bg-secondary-90 transition-all hover:bg-white hover:text-black"
        >
            <span className="text">Get Resume.pdf</span>
            <span className="icon">
                <i className="icofont-file-pdf" />
            </span>
        </Link>
        <Link
            to="/"
            className="flex justify-between mb-3 last:mb-0 px-7 py-5 bg-secondary-90 transition-all hover:bg-white hover:text-black"
        >
            <span className="text">Get Resume.doc</span>
            <span className="icon">
                <i className="icofont-file-word" />
            </span>
        </Link>
        <Link
            to="/"
            className="flex justify-between mb-3 last:mb-0 px-7 py-5 bg-secondary-90 transition-all hover:bg-white hover:text-black"
        >
            <span className="text">Get Resume.ppt</span>
            <span className="icon">
                <i className="icofont-file-powerpoint" />
            </span>
        </Link>
    </div>
);

export default Resume;
