import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const NotFound = () => (
    <div className="section-space-ptb md:h-screen flex items-center">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
                <div className="not-found-content">
                    <h2>Sorry!</h2>
                    <h3 className="mt-6">This Page is Not Found.</h3>
                    <p className="max-w-[460px] mt-6 mb-10">
                        Lorem Ipsum is simply dummy text of the printing and
                        dard dummy text ever since the 1500 when an uniknown
                        specimen book has not only five.
                    </p>
                    <a
                        href="/"
                        className="btn px-5 md:px-5 lg:px-10 h-16 leading-[65px] text-white text-[16px] inline-block md:mt-0 border border-solid border-secondary hover:bg-secondary rounded-full"
                    >
                        <i className="mr-4 icofont-double-left" /> Back To Home
                    </a>
                </div>
                <div className="not-found-image mt-10 md:mt-0">
                    <StaticImage
                        src="../images/404-error.webp"
                        alt="Error 404"
                    />
                </div>
            </div>
        </div>
    </div>
);
export default NotFound;
