/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const FAQCard = ({ id, title, description }) => (
    <div className="faq-card flex flex-col group items-center md:flex-row py-[60px] px-[55px] bg-secondary-80 border-solid border border-transparent hover:bg-secondary-90 hover:border hover:border-solid hover:border-white/10 mb-8">
        <div className="number w-[120px] h-[120px] leading-[120px] block group-hover:bg-secondary-80 bg-secondary-90 text-xl text-white mr-14 text-center shrink-0">
            {`${id <= 9 ? "0" : ""}${id}`}.
        </div>
        <div className="content">
            <h5 className="font-poppins font-medium mb-4">{title}</h5>
            <p>{description}</p>
        </div>
    </div>
);
FAQCard.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    description: PropTypes.string,
};
export default FAQCard;
