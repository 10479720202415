import React from "react";
import PropTypes from "prop-types";
import { ItemType } from "@lendex/shared";
import { Pagination, ProjectCard } from "gatsby-theme-component";

const ProjectArea = ({ data }) => (
    <div className="project-section section-space-ptb">
        <div className="container">
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                {data?.items?.map((item) => (
                    <ProjectCard
                        key={item.id}
                        title={item?.title}
                        image={item?.image}
                        slug={item?.slug}
                        catagory={item?.catagory}
                    />
                ))}
            </div>
            <div className="mt-14 text-center">
                {data?.pagination && data.pagination?.numberOfPages > 1 && (
                    <Pagination {...data.pagination} />
                )}
            </div>
        </div>
    </div>
);

ProjectArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        pagination: PropTypes.shape({
            rootPage: PropTypes.string,
            numberOfPages: PropTypes.number,
            currentPage: PropTypes.number,
        }),
    }),
};

export default ProjectArea;
