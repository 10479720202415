import { Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const Pagination = ({ className, currentPage, numberOfPages, rootPage }) => {
    const isFirst = currentPage === 1;
    const isLast = currentPage === numberOfPages;
    const previousPage =
        currentPage - 1 === 1
            ? rootPage
            : `${rootPage}/page/${(currentPage - 1).toString()}`;
    const nextPage = `${rootPage}/page/${(currentPage + 1).toString()}`;
    return (
        <nav className={cn("pagination", className)}>
            <ul className="flex">
                {isFirst ? (
                    <li className="prev cursor-not-allowed mr-2 w-16 h-16 text-center leading-[64px] border border-solid border-white/10">
                        <span>
                            <i className="icofont-double-left" />
                        </span>
                    </li>
                ) : (
                    <li className="prev mr-2 w-16 h-16 text-center leading-[64px] border border-solid border-white/10">
                        <a href={previousPage}>
                            <i className="icofont-double-left" />
                        </a>
                    </li>
                )}

                {Array.from({ length: numberOfPages }, (_, i) => (
                    <Fragment key={`fragment-${i + 1}`}>
                        {currentPage === i + 1 ? (
                            <li>
                                <a
                                    className="bg-secondary-80 w-16 h-16 block mx-1 text-center leading-[64px] border border-solid border-secondary-80"
                                    href={`${rootPage}/${
                                        i === 0 ? "" : `page/${i + 1}`
                                    }`}
                                >
                                    {i + 1}
                                </a>
                            </li>
                        ) : (
                            <li>
                                <a
                                    className="w-16 h-16 block mx-1 text-center leading-[64px] border border-solid border-white/10"
                                    href={`${
                                        i === 0
                                            ? rootPage
                                            : `${rootPage}/page/${i + 1}`
                                    }`}
                                >
                                    {i + 1}
                                </a>
                            </li>
                        )}
                    </Fragment>
                ))}
                {isLast ? (
                    <li className="next cursor-not-allowed w-16 ml-2 h-16 text-center leading-[64px] border border-solid border-white/10">
                        <span>
                            <i className="icofont-double-right" />
                        </span>
                    </li>
                ) : (
                    <li className="next w-16 ml-2 h-16 text-center leading-[64px] border border-solid border-white/10">
                        <a href={nextPage}>
                            <i className="icofont-double-right" />
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    rootPage: PropTypes.string,
    className: PropTypes.string,
};

export default Pagination;
