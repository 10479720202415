import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { flatDeep, containsObject } from "@lendex/helper/methods";

const CategoryItem = () => {
    const categoriesQuery = useStaticQuery(graphql`
        query CategoriesQuery {
            allArticle {
                edges {
                    node {
                        category {
                            slug
                            title
                        }
                    }
                }
            }
        }
    `);
    const categories = categoriesQuery.allArticle.edges;

    const allCategories = flatDeep(
        categories.map((cate) => cate.node.category)
    );
    const cats = [];
    allCategories.forEach((cat) => {
        const obj = {
            title: cat.title,
            slug: cat.slug,
            count: 1,
        };
        const objIndex = containsObject(obj, cats);
        if (objIndex !== -1) {
            const prevCount = cats[objIndex].count;
            cats[objIndex] = {
                title: cat.title,
                slug: cat.slug,
                count: prevCount + 1,
            };
        } else {
            cats.push(obj);
        }
    });
    return (
        <>
            {cats.map((cat) => (
                <Link
                    to={cat.slug}
                    key={cat.slug}
                    className="flex capitalize justify-between mb-3 last:mb-0 px-7 py-5 bg-secondary-90 transition-all hover:bg-white hover:text-black"
                >
                    <span className="text">{cat.title}</span>
                    <span className="icon">
                        <i className="icofont-double-right" />
                    </span>
                </Link>
            ))}
        </>
    );
};
CategoryItem.propTypes = {
    categories: PropTypes.shape({}),
};
export default CategoryItem;
