import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ButtonType, ItemType } from "@lendex/shared";
import { ServiceCard, SectionTitle } from "gatsby-theme-component";
import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";

const ServicesArea = ({ data }) => (
    <section className="service-section section-space-ptb">
        <div className="container">
            {data?.section_title && <SectionTitle {...data.section_title} />}
            <SwiperSlider
                options={{
                    autoplay: true,
                    slidesPerView: 1,
                    navigation: false,
                    pagination: true,
                    breakpoints: {
                        672: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    },
                }}
                navPosition={3}
                className="services-slider"
            >
                {data?.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <ServiceCard
                            title={item?.title}
                            features={item?.features}
                            images={item?.images?.[0]}
                        />
                    </SwiperSlide>
                ))}
            </SwiperSlider>
        </div>
    </section>
);
ServicesArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default ServicesArea;
