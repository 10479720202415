import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@lendex/helper/types";
import { Image } from "gatsby-theme-ui";

const ClientCard = ({ image, title }) => (
    <div className="company-logo-single-item group hover:bg-secondary-80 border-[#fdfdfd0d]/5 hover:border-secondary-80/0 border border-solid px-4 py-12 text-center opacity-80">
        <a href="/" aria-label="clien list" className="image text-center">
            {image?.src && (
                <Image
                    className="saturate-50 opacity-30 group-hover:opacity-100 group-hover:saturate-200"
                    src={image?.src}
                    alt={image?.alt || title}
                />
            )}
        </a>
    </div>
);
ClientCard.propTypes = {
    title: PropTypes.shape(),
    image: PropTypes.shape(ImageType),
};
export default ClientCard;
