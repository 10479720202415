import React from "react";
import PropTypes from "prop-types";
import { SectionTitle, Testimonial } from "gatsby-theme-component";
import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";
import { SectionTitleType, ButtonType, ItemType } from "@lendex/shared";

const TestimonialArea = ({ data }) => (
    <section className="testimonial-section section-space-ptb bg-secondary-80">
        <div className="container overflow-hidden">
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <div />
                {data?.section_title && (
                    <SectionTitle {...data.section_title} />
                )}
            </div>
            <SwiperSlider
                className="testimonial-slider-active"
                options={{
                    autoplay: false,
                    slidesPerView: 1,
                    navigation: true,
                    pagination: false,
                    spaceBetween: 30,
                    loop: true,
                    breakpoints: {
                        672: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                        1400: {
                            slidesPerView: 2,
                            spaceBetween: 150,
                        },
                    },
                }}
                navPosition={1}
            >
                {data?.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <Testimonial
                            title={item?.title}
                            description={item?.description}
                            designation={item?.designation}
                            company={item?.company}
                            rating={item?.rating}
                        />
                    </SwiperSlide>
                ))}
            </SwiperSlider>
        </div>
    </section>
);
TestimonialArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default TestimonialArea;
