/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-comment-textnodes */

import { Fragment, useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import cn from "classnames";
import { ImageType } from "@lendex/helper/types";
import { Image, ModalVideo, SwiperSlider, SwiperSlide } from "gatsby-theme-ui";

const sliderOptions = {
    slidesPerView: 1,
    autoplay: true,
    spaceBetween: 0,
};
const BlogMedia = ({
    className,
    format,
    image,
    title,
    slug,
    video_link,
    gallery_images,
    layout,
    isDetails,
    showVideoBtn,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    let video_arr;
    let video_id;
    let video_channel;
    if (video_link) {
        video_arr = video_link.split("=", -1);
        // eslint-disable-next-line prefer-destructuring
        video_id = video_arr[1];
        // eslint-disable-next-line prefer-destructuring
        video_channel = video_link.split(".")[1];
    }
    const onClickHandler = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className={cn("blog-media", className)}>
            {format === "image" && image?.src && (
                <Link to={slug} aria-label="Blog Thum" className="blog-thumb">
                    <Image
                        className="w-full"
                        src={image.src}
                        alt={image?.alt || title}
                    />
                </Link>
            )}
            {format === "video" && (
                <Link
                    to={slug}
                    aria-label="Blog Thum"
                    className="blog-video relative block"
                >
                    <Image
                        className="w-full"
                        src={image.src}
                        alt={image?.alt || title}
                    />
                    {showVideoBtn && (
                        <Fragment>
                            <button
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-10 h-10 leading-10 bg-gray-50 text-black text-center "
                                onClick={onClickHandler}
                                aria-label="button"
                            >
                                <i className="icofont-play" />
                            </button>
                            <ModalVideo
                                channel={video_channel}
                                isOpen={isOpen}
                                videoId={video_id}
                                onClose={onClickHandler}
                            />
                        </Fragment>
                    )}
                </Link>
            )}
            {format === "gallery" && gallery_images && (
                <SwiperSlider options={sliderOptions}>
                    {gallery_images.map((img) => (
                        <SwiperSlide key={img.src?.absolutePath}>
                            <div
                                className="blog-thumb"
                                layout={!isDetails && layout}
                            >
                                <Image
                                    className="w-full"
                                    src={img.src}
                                    alt={img?.alt || title}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </SwiperSlider>
            )}
        </div>
    );
};
BlogMedia.propTypes = {
    format: PropTypes.oneOf(["image", "gallery", "video"]),
    image: PropTypes.shape(ImageType),
    video_link: PropTypes.string,
    gallery_images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    title: PropTypes.string,
    slug: PropTypes.string,
    className: PropTypes.string,
    layout: PropTypes.oneOf([1, 2, 3]),
    isDetails: PropTypes.bool,
    showVideoBtn: PropTypes.bool,
    sx: PropTypes.shape({}),
};

BlogMedia.defaultProps = {
    layout: 1,
    isDetails: false,
    showVideoBtn: true,
};

export default BlogMedia;
