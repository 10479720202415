import { useState } from "react";
import { useForm } from "react-hook-form";
import { hasKey } from "@lendex/helper/methods";
import { formUrl } from "@lendex/shared/data/api-keys";
import axios from "axios";

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        });
        if (ok) {
            form.reset();
        }
    };

    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data,
        })
            .then(() => {
                handleServerResponse(true, "Thanks! for contact with us", form);
            })
            .catch((r) => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    return (
        <form
            className="contact-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <div className="grid gap-8 grid-cols-2">
                <div className="col-span-1">
                    <div className="input-group">
                        <input
                            className="bg-transparent border border-solid border-white/10 w-full py-5 px-4"
                            type="text"
                            id="con_name"
                            placeholder="Enter Your Name"
                            // feedbackText={errors?.con_name?.message}
                            state={
                                hasKey(errors, "con_name") ? "error" : "success"
                            }
                            // showState={!!hasKey(errors, "con_name")}
                            {...register("con_name", {
                                required: "Name is required",
                                minLength: {
                                    value: 2,
                                    message: "Minimum length is 2",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className=" col-span-1">
                    <div className="input-group">
                        <input
                            className="bg-transparent border border-solid border-white/10 w-full py-5 px-4"
                            type="email"
                            id="con_email"
                            placeholder="Enter Your Email"
                            // feedbackText={errors?.con_email?.message}
                            state={
                                hasKey(errors, "con_email")
                                    ? "error"
                                    : "success"
                            }
                            // showState={!!hasKey(errors, "con_email")}
                            {...register("con_email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className=" col-span-1">
                    <div className="input-group">
                        <input
                            className="bg-transparent border border-solid border-white/10 w-full py-5 px-4"
                            type="text"
                            id="con_phone"
                            placeholder="Phone"
                            // feedbackText={errors?.con_phone?.message}
                            state={
                                hasKey(errors, "con_phone")
                                    ? "error"
                                    : "success"
                            }
                            // showState={!!hasKey(errors, "con_phone")}
                            {...register("con_phone", {
                                required: "Name is phone]",
                                minLength: {
                                    value: 2,
                                    message: "Minimum length is 2",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className=" col-span-1">
                    <div className="input-group">
                        <input
                            className="bg-transparent border border-solid border-white/10 w-full py-5 px-4"
                            type="text"
                            id="con_subject"
                            placeholder="Subject"
                            // feedbackText={errors?.con_email?.message}
                            state={
                                hasKey(errors, "con_subject")
                                    ? "error"
                                    : "success"
                            }
                            // showState={!!hasKey(errors, "con_subject")}
                            {...register("con_subject", {
                                required: "Subject",
                                minLength: {
                                    value: 2,
                                    message: "Minimum length is 2",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="col-span-2">
                    <textarea
                        className="bg-transparent border border-solid border-white/10 w-full py-5 px-4 h-56"
                        id="con_message"
                        placeholder="Message"
                        // feedbackText={errors?.con_message?.message}
                        state={
                            hasKey(errors, "con_message") ? "error" : "success"
                        }
                        // showState={!!hasKey(errors, "con_message")}
                        {...register("con_message", {
                            required: "Message is required",
                            minLength: {
                                value: 2,
                                message: "Minimum length is 10",
                            },
                        })}
                    />
                </div>
            </div>

            <button
                type="submit"
                disabled={serverState.submitting}
                className="btn mt-10 px-5 md:px-5 lg:px-10 h-16 leading-[65px] text-white text-[18px] inline-block border border-solid border-secondary rounded-full"
            >
                Send Message
            </button>
            {serverState.status && (
                <p
                    className={`form-output ${
                        !serverState.status.ok ? "errorMsg" : "success"
                    }`}
                >
                    {serverState.status.msg}
                </p>
            )}
        </form>
    );
};
export default ContactForm;
