import React from "react";
import PropTypes from "prop-types";
import { CompleteHistoryCard } from "gatsby-theme-component";
import { SectionTitleType } from "@lendex/helper/types";

const CompleteHisory = ({ data }) => (
    <div className="project-history mt-14 mb-14">
        <div className="section-title mb-10">
            {data?.section_title && (
                <h2 className="lg:text-[50px] font-semibold">
                    {data?.section_title?.title}
                </h2>
            )}
            {data?.section_title.description && (
                <p className="mt-4">{data?.section_title?.description}</p>
            )}
        </div>
        {data?.items &&
            data.items.map((item) => (
                <CompleteHistoryCard
                    key={item.id}
                    title={item.title}
                    iconClass={item.iconClass}
                    description={item.description}
                />
            ))}
    </div>
);

CompleteHisory.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape()),
    }),
};

export default CompleteHisory;
