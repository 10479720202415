import React from "react";
import PropTypes from "prop-types";
import { FAQCard } from "gatsby-theme-component";
import { ItemType } from "@lendex/shared";

const FAQArea = ({ data }) => (
    <section className="faq-section section-space-ptb">
        <div className="container">
            <div className="faq-content">
                {data?.items &&
                    data.items.map((item) => (
                        <FAQCard
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
            </div>
        </div>
    </section>
);
FAQArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default FAQArea;
