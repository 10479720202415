/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-array-index-key */
import { Link } from "gatsby";
import PropTypes from "prop-types";
import cn from "classnames";

const BreadCrumb = ({ prev, className }) => (
    <div className={cn("page-title-content text-center", className)}>
        <h1 className="title text-3xl md:text-6xl lg:text-[72px] capitalize mb-8">
            Project Details
        </h1>
        {prev?.map((item, i) =>
            item?.link ? (
                <Link key={i} to={item.link} className="capitalize">
                    {item.text} ||{" "}
                </Link>
            ) : (
                <span>{item.text} || </span>
            )
        )}
        <span className="text-white">Project Details</span>
    </div>
);
BreadCrumb.propTypes = {
    className: PropTypes.string,
    prev: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string,
        })
    ).isRequired,
};
export default BreadCrumb;
