/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo } from "react";
import { Portal } from "react-portal";
import cn from "classnames";
import PropTypes from "prop-types";
import OffCanvasHeader from "./header";
import OffCanvasBody from "./body";

const OffCanvas = ({ children, onClick, isOpen, className, ...rest }) => (
    <Portal>
        <div
            className={cn(
                isOpen === false && "top-0 right-0 fixed invisible opacity-0",
                isOpen &&
                    "visible opacity-100 overflow-y-hidden z-10 bg-black/70 w-full h-full top-0 left-0 fixed"
            )}
            onClick={onClick}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={cn(
                    className,
                    "offcanvas",
                    `w-[300px] bg-white float-right  h-full ${
                        isOpen ? "active" : ""
                    }`
                )}
                {...rest}
            >
                {children}
            </div>
        </div>
    </Portal>
);

OffCanvas.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

export { OffCanvasHeader, OffCanvasBody };
export default memo(OffCanvas);
