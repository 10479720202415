import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { ImageType } from "@lendex/helper/types";
import { Image } from "gatsby-theme-ui";

const ProjectCard = ({ title, image, catagory, slug }) => (
    <div className="project-card">
        <Link
            to={slug}
            aria-label={title}
            className="img-box block px-10 md:px-14 xl:px-24 pt-10 md:pt-14 xl:pt-24 pb-0 h-[400px] xl:h-[568px] relative overflow-hidden"
        >
            <div className="bg-overlay absolute top-0 left-0 w-full h-full bg-[rgba(37,39,52,.95)] opacity-90 z-10" />
            <div className="bg-image absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-45 -z-0">
                {image?.src && (
                    <Image src={image.src} alt={image.alt || title} />
                )}
            </div>
            <div className="image z-20 relative">
                {image?.src && (
                    <Image src={image.src} alt={image.alt || title} />
                )}
            </div>
        </Link>

        <h3 className="title font-poppins font-medium mt-14 mb-4  text-[22px] md:text-[28px]">
            <Link to={slug} className="text-3xl">
                {title}
            </Link>
        </h3>
        <ul className="catagory-nav-item flex ">
            {catagory &&
                catagory.map((cat) => (
                    <li
                        key={cat.title}
                        className="relative pr-2 mr-2 after:absolute after:content-['/'] after:right-[-0.2rem] last:after:hidden"
                    >
                        {cat.title}
                    </li>
                ))}
        </ul>
    </div>
);
ProjectCard.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.shape(ImageType),
    catagory: PropTypes.arrayOf(PropTypes.shape()),
};
export default ProjectCard;
