import React from "react";
import PropTypes from "prop-types";
import { ImageType, TextType } from "@lendex/helper/types";
import { Image } from "gatsby-theme-ui";

const ServiceCard = ({ title, images, features }) => (
    <div className="service-box-single-item bg-secondary-80 py-16 px-16 relative overflow-hidden">
        <div
            className="inner-shape absolute top-[-230px] right-[-230px] w-[345px] h-[345px] rounded-full border border-[rgba(255,255,255,.05)]
         after:absolute after:w-[460px] after:h-[460px] after:rounded-full after:border after:border-[rgba(255,255,255,.05)] after:top-[-40px] after:right-[-40px]
         before:absolute before:w-[400px] before:h-[400px] before:rounded-full before:border before:border-[rgba(255,255,255,.05)] before:top-[-20px] before:right-[-20px]"
        />
        <div className="icon mb-14 w-[56px] h-[56px]">
            {images && <Image src={images?.src} alt={images.alt || title} />}
        </div>
        <h3 className="title mb-11 font-poppins  text-[22px] md:text-[25px]">
            <a href="/" className="text-3xl">
                {title}
            </a>
        </h3>
        <ul className="list-item">
            {features?.map((feat) => (
                <li
                    className="block pl-7 relative mb-4 last:mb-0 before:content-[''] before:font-IcoFont before:left-0 before:top-0 before:absolute"
                    key={feat.id}
                >
                    {feat.content}
                </li>
            ))}
        </ul>
        <div className="inner-shape inner-shape-bottom-right" />
    </div>
);
ServiceCard.propTypes = {
    title: PropTypes.string,
    images: PropTypes.shape(ImageType),
    features: PropTypes.arrayOf(PropTypes.shape(TextType)),
};
export default ServiceCard;
