import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ButtonType, ItemType } from "@lendex/shared";
import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";
import { ProjectCard, SectionTitle } from "gatsby-theme-component";

const ProjectsArea = ({ data }) => (
    <section className="project-section section-space-ptb">
        <div className="container">
            {data?.section_title && <SectionTitle {...data.section_title} />}
        </div>
        <SwiperSlider
            className="project-display-wrapper"
            options={{
                autoplay: false,
                slidesPerView: 1,
                navigation: false,
                pagination: false,
                spaceBetween: 40,
                centeredSlides: false,
                breakpoints: {
                    672: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                    1200: {
                        centeredSlides: true,
                        spaceBetween: 50,
                        slidesPerView: 3,
                    },
                },
            }}
            navPosition={2}
        >
            {data?.items?.map((item) => (
                <SwiperSlide key={item.id}>
                    <ProjectCard
                        title={item?.title}
                        image={item?.image}
                        slug={item?.slug}
                        catagory={item?.catagory}
                    />
                </SwiperSlide>
            ))}
        </SwiperSlider>
    </section>
);
ProjectsArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default ProjectsArea;
