/* eslint-disable react/button-has-type */
import React from "react";
import { useForm } from "react-hook-form";
import { hasKey } from "@lendex/helper/methods";

const NewsletterForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        // eslint-disable-next-line no-console
        console.log(data);
    };
    return (
        <div className="newsletter-area text-center">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <input
                    className="py-5 px-10 mr-6 w-[315px] lg:w-[615px] rounded-[35px] focus:border-[rgba(255,255,255,.1)] border border-solid border-[rgba(255,255,255,.1)] bg-transparent"
                    type="email"
                    id="news_email"
                    placeholder="demo@example.com"
                    // feedbackText={errors?.news_email?.message}
                    state={hasKey(errors, "news_email") ? "error" : "success"}
                    // showState={!!hasKey(errors, "news_email")}
                    {...register("news_email", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address",
                        },
                    })}
                />
                <button className="py-5 hover:bg-white hover:text-black mt-4 sm:mt-0 px-10 border text-white border-solid border-secondary-60 bg-secondary-60 rounded-[35px]">
                    Subscribe Now
                </button>
            </form>
        </div>
    );
};

export default NewsletterForm;
