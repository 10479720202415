import { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { ImageType, ButtonType } from "@lendex/helper/types";
import { Logo, MainMenu, MenuOffcanvas } from "gatsby-theme-component";
import { BurgerButton } from "gatsby-theme-ui";
import { Link } from "gatsby";
// import { useSticky } from "@lendex/helper/hooks";
const HeaderOne = ({ data }) => {
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);
    // const { sticky, headerRef, headerHeight } = useSticky();

    const offCanvasHandler = useCallback(() => {
        setOffCanvasOpen((prev) => !prev);
    }, [setOffCanvasOpen]);

    return (
        <Fragment>
            <header className="header absolute py-8 z-10 left-0 m-auto right-0 w-full">
                <div className="container">
                    <div className="flex justify-between items-center">
                        {data?.logo && <Logo mainLogo={data.logo?.[0]} />}
                        <div className="hidden lg:block">
                            <MainMenu menu={data?.menu} />
                        </div>
                        <div className="flex items-center">
                            <Link
                                to="/contact"
                                className="btn hidden xs:block hover:bg-secondary px-5 md:px-5 lg:px-10 h-16 leading-[65px]
                                    text-white text-[18px] md:mt-0 border border-solid border-secondary rounded-full"
                            >
                                Hire Me
                                <i className=" ml-4 icofont-double-right" />
                            </Link>
                            <div className="lg:hidden">
                                <BurgerButton onClick={offCanvasHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MenuOffcanvas
                isOpen={offCanvasOpen}
                onClick={offCanvasHandler}
                logo={data?.logo}
                menu={data?.menu}
            />
        </Fragment>
    );
};
HeaderOne.propTypes = {
    data: PropTypes.shape({
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        button: PropTypes.shape(ButtonType),
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};
export default HeaderOne;
