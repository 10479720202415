import { Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination, Autoplay, A11y } from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css";

const SwiperSlider = ({
    options,
    prevIcon,
    nextIcon,
    children,
    className,
    sx,
}) => {
    const date = new Date().getTime();
    const prevClass = `prev-${date}`;
    const nextClass = `next-${date}`;

    const sliderOptions = {
        modules: [Navigation, Pagination, A11y, Autoplay],
        slidesPerView: 3,
        spaceBetween: 30,
        loop: false,
        centeredSlides: false,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        watchSlidesProgress: true,
        ...options,
        navigation: options?.navigation
            ? {
                  prevEl: `.${prevClass}`,
                  nextEl: `.${nextClass}`,
              }
            : false,
        pagination: options?.pagination ? { clickable: true } : false,
    };

    return (
        <div className={cn(className, "swiper-wrap")} sx={sx}>
            <Swiper {...sliderOptions}>{children}</Swiper>
            {sliderOptions.navigation && (
                <Fragment>
                    <button
                        type="button"
                        className={`swiper-btn swiper-btn-prev ${prevClass}`}
                    >
                        <i className={prevIcon} />
                    </button>
                    <button
                        type="button"
                        className={`swiper-btn swiper-btn-next ${nextClass}`}
                    >
                        <i className={nextIcon} />
                    </button>
                </Fragment>
            )}
        </div>
    );
};

export { SwiperSlide };

SwiperSlider.propTypes = {
    options: PropTypes.shape({
        navigation: PropTypes.bool,
        pagination: PropTypes.bool,
        loop: PropTypes.bool,
        centeredSlides: PropTypes.bool,
        slidesPerView: PropTypes.number,
        spaceBetween: PropTypes.number,
        autoplay: PropTypes.bool,
        breakpoints: PropTypes.shape({}),
    }),
    prevIcon: PropTypes.string,
    nextIcon: PropTypes.string,
    navStyle: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    navPosition: PropTypes.oneOf([1, 2, 3, 4, 5]),
    shadowSize: PropTypes.oneOf(["md", "lg"]),
    dotStyle: PropTypes.oneOf([1, 2, 3]),
    dotPosition: PropTypes.oneOf([1, 2, 3]),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    sx: PropTypes.shape({}),
};

SwiperSlider.defaultProps = {
    prevIcon: "icofont-double-left",
    nextIcon: "icofont-double-right",
    navStyle: 1,
    navPosition: 1,
    dotStyle: 1,
    dotPosition: 1,
};

export default SwiperSlider;
