import { useState } from "react";
import PropTypes from "prop-types";
import {
    HeadingType,
    TextType,
    ImageType,
    VideoButtonType,
} from "@lendex/helper/types";
import { Image, Heading, ModalVideo } from "gatsby-theme-ui";

const HeroArea = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClickHandler = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <section className="hero-section relative h-[980px] sm:h-[980px] md:h-[590px] lg:h-[740px] 2xl:h-[900px] 3xl:h-[1100px] bg-secondary-80 overflow-hidden">
            <div className="container">
                <div className="hero-content bottom-[60px] translate-y-0 md:top-1/2 md:-translate-y-1/2 absolute md:bottom-auto md:mt-16 2xl:mt-0 z-10">
                    {data?.headings?.[0] && (
                        <Heading
                            level={data.headings[0]?.level}
                            className="title-big text-white"
                        >
                            {data.headings[0].content}
                        </Heading>
                    )}
                    {data?.headings?.[1] && (
                        <Heading
                            level={data.headings[1]?.level}
                            className="title-large text-white"
                        >
                            {data.headings[1].content}
                        </Heading>
                    )}
                    {data?.texts?.[0] && (
                        <p
                            className="text-base lg:text-xl text-white mt-8 mb-10 "
                            dangerouslySetInnerHTML={{
                                __html: data?.texts[0].content,
                            }}
                        />
                    )}
                    <a
                        href="/"
                        className="btn hover:bg-secondary px-5 md:px-5 lg:px-10 h-16 leading-[65px] inline-block md:mt-0 border border-solid border-secondary rounded-full"
                    >
                        Get Resume <i className="icofont-download" />
                    </a>

                    <div className="video-link flex items-center mt-5 lg:mt-20 xxl:mt-24">
                        <button
                            className="wave-btn relative h-24 w-24 leading-[96px] z-10 bg-secondary-90 text-center rounded-full"
                            onClick={onClickHandler}
                            type="button"
                            aria-label="button"
                        >
                            <span className="animate-ping inline-flex h-full w-full rounded-full -z-10 bg-[#494c5f] opacity-75" />
                            <div className="ripple z-10 absolute top-0 left-1/2">
                                <i className="icofont-ui-play" />
                            </div>
                        </button>

                        <span className="video-text pl-8"> Watch Video</span>
                    </div>
                    <ModalVideo
                        isOpen={isOpen}
                        channel={data?.video_button?.video_platform}
                        videoId={data?.video_button?.video_id}
                        onClose={onClickHandler}
                    />
                </div>
            </div>
            <div className="hero-shape hero-top-shape">
                <span />
                <span />
                <span />
            </div>
            <div className="hero-shape hero-bottom-shape">
                <span />
                <span />
                <span />
            </div>
            <div className="hero-portrait">
                <div className="image">
                    {data?.images?.[0].src && (
                        <Image
                            className="vector-agency w-auto h-auto"
                            src={data.images[0].src}
                            alt={data.images[0]?.alt || "Hero Bg"}
                        />
                    )}

                    <div className="image-half-round-shape" />
                    <div className="social-link">
                        <a
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="facebook"
                        >
                            <i className="icofont-facebook" />
                        </a>
                        <a
                            href="https://dribbble.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="dribbble"
                        >
                            <i className="icofont-dribbble" />
                        </a>
                        <a
                            href="https://www.behance.net/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="behance"
                        >
                            <i className="icofont-behance" />
                        </a>
                        <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="linkedin"
                        >
                            <i className="icofont-linkedin" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};
HeroArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        video_button: PropTypes.shape(VideoButtonType),
    }),
};
export default HeroArea;
