import React from "react";
import PropTypes from "prop-types";
import { ImageType, BlogFormatType } from "@lendex/helper/types";
import cn from "classnames";
import { Link } from "gatsby";
import BlogMedia from "../../blog-media";

const BlogCard = ({
    title,
    slug,
    date,
    category,
    image,
    format,
    video_link,
    gallery_images,
    className,
}) => (
    <article
        className={cn(
            className,
            "blog-card flex flex-col sm:flex-row items-center justify-center mb-10 last:mb-0 md:px-6 md:py-12 lg:p-12 bg-secondary-70 overflow-hidden relative"
        )}
    >
        <div
            className="inner-shape absolute top-[-230px] right-[-230px] w-[345px] h-[345px] rounded-full border border-[rgba(255,255,255,.05)]
         after:absolute after:w-[460px] after:h-[460px] after:rounded-full after:border after:border-[rgba(255,255,255,.05)] after:top-[-40px] after:right-[-40px]
         before:absolute before:w-[400px] before:h-[400px] before:rounded-full before:border before:border-[rgba(255,255,255,.05)] before:top-[-20px] before:right-[-20px]"
        />
        <BlogMedia
            className="sm:max-w-[180px] w-full sm:mr-4 md:mr-6 lg:mr-16"
            format={format}
            image={image}
            title={title}
            slug={slug}
            video_link={video_link}
            gallery_images={gallery_images}
        />
        <div className="content-box mt-7 sm:mt-0 md:flex w-full self-center md:items-center md:justify-between">
            <div className="content md:max-w-[340px] lg:max-w-[450px] shrink-0">
                <div className="post-meta font-light mb-4">
                    <Link
                        to={category.slug}
                        className="catagory capitalize text-white"
                    >
                        {category.title}
                    </Link>
                    <span className="mx-2 font-semibold text-white inline-block">
                        -
                    </span>
                    <Link to={slug} className="date">
                        {date}
                    </Link>
                </div>
                <h3 className="title font-poppins font-medium text-[22px] md:text-[25px]">
                    <Link to={slug}>{title}</Link>
                </h3>
            </div>
            <Link
                to={slug}
                className="btn capitalize px-5 md:px-5 lg:px-10 h-16 text-[15px] hover:bg-secondary leading-[65px] inline-block mt-6 md:mt-0 border border-solid border-secondary rounded-full"
            >
                read more
                <span className="sr-only">{title}</span>
                <i className="ml-1 icofont-double-right" />
            </Link>
        </div>
    </article>
);
BlogCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    // author: PropTypes.shape({
    //     username: PropTypes.string.isRequired,
    //     slug: PropTypes.string.isRequired,
    // }).isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.shape(ImageType),
    format: BlogFormatType,
    video_link: PropTypes.string,
    gallery_images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    category: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }),
};
export default BlogCard;
