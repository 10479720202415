import React from "react";
import PropTypes from "prop-types";

const BlogWidgets = ({ children }) => (
    <div className="blog-widgets bg-secondary-80 py-12 px-8 xl:px-16 mb-10 last:mb-0">
        {children}
    </div>
);
BlogWidgets.propTypes = {
    children: PropTypes.node.isRequired,
};
export default BlogWidgets;
