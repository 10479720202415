import React from "react";
import PropTypes from "prop-types";

const CompleteHistory = ({ title, description, iconClass }) => (
    <div className="flex flex-col sm:flex-row mb-10 last:mb-0">
        <div className="icon shrink-0 mb-7 sm:mb-0 mr-10 text-[35px] text-center leading-[96px] w-24 h-24 rounded-full border border-solid border-white/10">
            <i className={iconClass} />
        </div>
        <div className="content mt-4">
            <h3 className="font-poppins font-medium mb-2">{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

CompleteHistory.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    iconClass: PropTypes.string,
};

export default CompleteHistory;
