import React from "react";
import PropTypes from "prop-types";
import { ProgressBar } from "gatsby-theme-ui";

const SkillItem = ({ title, completed }) => (
    <div className="skill-progress-single-item py-14 px-14 bg-secondary-90 mb-8 last:mb-0">
        <span className="tag mb-3 inline-block">{title}</span>
        <div className="progress-bar">
            <ProgressBar completed={completed} />
        </div>
    </div>
);
SkillItem.propTypes = {
    title: PropTypes.string,
    completed: PropTypes.number,
};

export default SkillItem;
