import React from "react";
import PropTypes from "prop-types";
import { Client, SectionTitle } from "gatsby-theme-component";

import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";
import { SectionTitleType, ButtonType, ItemType } from "@lendex/shared";

const ClientArea = ({ data }) => (
    <section className="client-section section-space-ptb">
        <div className="container">
            {data?.section_title && <SectionTitle {...data.section_title} />}
            <SwiperSlider
                options={{
                    autoplay: true,
                    slidesPerView: 2,
                    navigation: false,
                    spaceBetween: 30,
                    pagination: false,
                    breakpoints: {
                        672: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 85,
                        },
                    },
                }}
                navPosition={3}
            >
                {data?.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <Client title={item?.title} image={item?.images?.[0]} />
                    </SwiperSlide>
                ))}
            </SwiperSlider>
        </div>
    </section>
);
ClientArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default ClientArea;
