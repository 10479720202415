import React from "react";
import PropTypes from "prop-types";

const SectionTitle = ({ subtitle, title, description }) => (
    <div className="section-title mb-10 md:mb-14 xl:mb-24">
        <span className="text-lg font-light relative inline-block pl-7 before:content-['||'] before:left-0 before:top-0 before:absolute">
            {subtitle}
        </span>
        <h2 className="mt-6 mb-3 ">{title}</h2>
        {description && <p>{description}</p>}
    </div>
);
SectionTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
};

export default SectionTitle;
