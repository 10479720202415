/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import { ImageType } from "@lendex/helper/types";
import { Link } from "gatsby";

const Logo = ({ className, mainLogo }) => (
    <Link to="/">
        <Image
            className="logo-main"
            src={mainLogo?.src}
            alt={mainLogo?.alt || "Logo"}
        />
    </Link>
);
Logo.propTypes = {
    className: PropTypes.string,
    mainLogo: PropTypes.shape(ImageType),
};

export default Logo;
