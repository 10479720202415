import React from "react";
import PropTypes from "prop-types";
import { ImageType, TextType } from "@lendex/helper/types";

const ProjectOverview = ({ data }) => (
    <div className="project-overview">
        <div className="project-sidebar bg-secondary-80 px-10 py-10">
            <div className="title mb-10">
                <h4>Information</h4>
            </div>
            {data?.name && (
                <div className="box py-6 px-8 bg-secondary-90 mb-3">
                    <span className=" font-semibold block text-white mb-3">
                        Project Name:
                    </span>
                    {data.name}
                </div>
            )}
            {data?.client && (
                <div className="box py-6 px-8 bg-secondary-90 mb-3">
                    <span className=" font-semibold block text-white mb-3">
                        Clients:
                    </span>
                    {data.client}
                </div>
            )}
            {data?.budget && (
                <div className="box py-6 px-8 bg-secondary-90 mb-3">
                    <span className=" font-semibold block text-white mb-3">
                        Budget:
                    </span>{" "}
                    {data.budget}
                </div>
            )}
            {data?.duration && (
                <div className="box py-6 px-8 bg-secondary-90 mb-3">
                    <span className=" font-semibold block text-white mb-3">
                        Duration:
                    </span>{" "}
                    {data.duration}
                </div>
            )}
            {data?.duration && (
                <div className="box py-6 px-8 bg-secondary-90 mb-3">
                    <span className=" font-semibold block text-white mb-3">
                        Date:
                    </span>{" "}
                    {data.date}
                </div>
            )}
        </div>
    </div>
);

ProjectOverview.propTypes = {
    data: PropTypes.shape({
        image: PropTypes.shape(ImageType),
        title: PropTypes.string,
        overview: PropTypes.arrayOf(PropTypes.shape(TextType)),
        name: PropTypes.string,
        client: PropTypes.string,
        website: PropTypes.string,
        budget: PropTypes.string,
        duration: PropTypes.string,
        date: PropTypes.string,
    }),
};

export default ProjectOverview;
